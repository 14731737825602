/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, Route, useLocation, Link } from "react-router-dom";
//import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Homepage from "./pages/Homepage";
import AboutSite from "./pages/AboutSitePage";
import ContactUsPage from "./pages/ContactUsPage";
// import WorkExpressions from "./pages/WorkExpressions";
import MyProfile from "./Components/Profile/MyProfile";
import Pagnation from "./Components/FieldWords/Pagnation/Pagnation";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  // emptyAction,
  reduxGet,
  reduxPost,
} from "./redux/actions/reusableActions";
import { GET_ABOUT, GET_CATEGORIES, GET_CONTACT, GET_MY_DATA, GET_SLIDERS } from "./redux/actions/types";
import Footer from "./Components/HomePage/Footer/Footer";
import Navbar from "./Components/HomePage/Navbar/Navbar";
// import FieldsHeader from "./Components/BusinessTerms/FieldsHeader";
import FieldWords from "./Components/FieldWords/FieldWords";
import { AiOutlineMenu } from "react-icons/ai";
import Sources from "./Components/sources/Sources";
import Fade from "react-reveal/Fade";
import { Toaster } from "react-hot-toast";
import Sitemap from "./pages/Sitemap";
import useWindowSize from "./useWindowSize";
import MobileNavbar from "./Components/HomePage/Navbar/MobileNavbar";
import AppModal from "./Components/ReusableComponents/AppModal";
import Survey from "./Components/Survey";

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth?.user?.token);
  const user = useSelector((state) => state.user?.user?.data);
  const [showNav, setShowNav] = React.useState(false);
  const { pathname } = useLocation();
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    if (token) {
      dispatch(
        reduxPost(
          "auth/user-profile",
          { token },
          () => {},
          () => {},
          () => {},
          GET_MY_DATA
        )
      );
    }
  }, [token]);

  const getProfileRequest = () => {
    dispatch(
      reduxPost(
        "auth/user-profile",
        { token },
        () => {},
        () => {},
        () => {},
        GET_MY_DATA
      )
    );
  };
  // console.log("MY TOKEN", token);

  React.useEffect(() => {
    dispatch(reduxGet("get-about-site", GET_ABOUT));
    dispatch(reduxGet("get-categories", GET_CATEGORIES));
    dispatch(reduxGet("site-info", GET_CONTACT));
    dispatch(reduxGet("get-intro?lang=ar", GET_SLIDERS));
  }, []);

  // console.log("USER", user);

  React.useEffect(() => {
    setShowNav(false);
    window.scrollTo(0, 0);
  }, [pathname]);
  const { width } = useWindowSize();
  React.useEffect(() => {
    if (localStorage.getItem("survey") !== "done") {
      setTimeout(() => {
        setShow(true);
      }, 5000);
    }
  }, [token, user]);

  return (
    <div className="my-app container" style={{ background: "white", padding: "0" }}>
      <AppModal
        show={show}
        setShow={setShow}
        modalTitle="استبيان سريع"
        modalBody={<Survey setShow={setShow} getUser={getProfileRequest} />}
      />
      <div style={{ direction: "rtl", padding: "10px", paddingBottom: "0px" }} className="flex-between top-head-menu">
        <div style={{ height: "100%" }} className="flex-center">
          <AiOutlineMenu onClick={() => setShowNav(!showNav)} size="40" className="mt-2" />
        </div>
        <Link to="/">
          <img style={{ height: "70px" }} src="/assets/logo-glossary.png" alt="logo-glossary" className="mt-2" />
        </Link>
      </div>
      {width < 1000 ? <MobileNavbar showNav={showNav} /> : <Navbar showNav={showNav} />}
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/contact-us" element={<ContactUsPage />}></Route>
        <Route path="/about-site" element={<AboutSite />}></Route>
        <Route path="/site-map" element={<Sitemap />}></Route>
        {/* <Route path="/expressions" element={<WorkExpressions />}></Route> */}
        <Route path="/my-profile" element={<MyProfile />}></Route>
        <Route path="/expressions" element={<FieldWords title="مصطلحات العمل" id={1} />}></Route>
        <Route path="/expressions/:catId" element={<FieldWords title="مصطلحات العمل" id={1} />}></Route>
        <Route path="/tech" element={<FieldWords title="الاتصالات وتكنولوجيا المعلومات" id={2} />}></Route>
        <Route path="/accounting" element={<FieldWords title="المحاسبة والمالية" id={3} />}></Route>
        <Route path="/resources" element={<Sources />}></Route>
        <Route path="pagnation" element={<Pagnation />}></Route>
      </Routes>
      <Footer />
      <Toaster style={{ padding: "100px" }} />
    </div>
  );
}

export default App;
