import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { chunkArray } from "../../helperFunctions";
import api from "../../redux/api";
import { useData } from "../../useData";
import FieldsHeader from "../BusinessTerms/FieldsHeader";
import Footer from "../HomePage/Footer/Footer";
import Style from "./fieldWords.module.css";
import LazyLoading from "./LazyLoadingWords";

import { Fade } from "react-reveal";
import { imgUrl } from "../../variables";
const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const FieldWords = ({ title, id }) => {
  const { catId } = useParams();
  const categories = useSelector((state) => state.webData?.categories);
  const [image,setImage]=useState();
  const [path,setPath]=useState();
  const currentCategory = categories?.find((item) => item.slug === catId);

  // console.log("CURRENT CATEGORY", currentCategory);
  // const words = useData(`get-category/${id}`);

  // const [words, setWords] = React.useState({});
  // React.useEffect(() => {
  //   api.get(`get-category/${id}`).then((res) => setWords(res?.data));
  // }, [id]);
  const [currentIndex, setCurrIndex] = React.useState(0);
  console.log("CURRENT INDEX", currentIndex);
  React.useEffect(() => {
   
    console.log('catId',catId);

    console.log('1',categories[0].image)
    console.log('2',categories[1].image)
    if(catId==='سوق-العمل'){
      setImage(categories[0].image)
    }else{
      setImage(categories[1].image)
    }
    setPath(categories[0].path)
  }, [catId]);

  const lettersAlphanet = letters.map((el, index) => (
    <div className="flex-between" key={index}>
      <div
        className="flex-center"
        style={{
          height: "55px",
          width: "55px",
          borderRadius: "100%",
          border: "1px solid #000",
          margin: "10px",
          background: currentIndex === index ? "#1fbacf" : "none",
          cursor: "pointer",
        }}
        onClick={() => setCurrIndex(index)}
      >
        <h3
          className="letter-container"
          style={{
            color: currentIndex === index ? "#ffffff" : "#1fbacf",
            marginBottom: "0",
          }}
        >
          {el}
        </h3>
      </div>
    </div>
  ));

  const chartFilter = currentCategory?.translation_data?.filter(
    (item) => item?.en_term?.charAt(0) === letters[currentIndex]
  );

  const sortedWords = chartFilter?.sort();
  // console.log("CHART CHECK", "chart".charAt(0));

  // console.log("SORTED", sortedWords);

  const sortedWordsChunks = chunkArray(sortedWords ? sortedWords : [], 16);

  return (
    <div className="mb-5">
      {/* <FieldsHeader img={image} path={path} title={currentCategory?.ar_term} /> */}
      <div
      className={Style.businessterms} 
      style={{ background: "rgba(16, 110, 127, 0.6)" ,height:"100%"}}
      
    >
<div className="header-2" style={{ background: "rgba(16, 110, 127, 0.6)", position: "relative" }}>
  <img src={`${imgUrl}/${path}/${image}`} style={{ width: '100%', height: '28rem', objectFit: 'cover' }} />
  <div style={{
    position: "absolute",
    top: "0",
    left: "0",
     width:'100%',
     height:'100%',
     background:'rgba(16, 110, 127, 0.6)'
  }}>

  </div>
  <div style={{
    position: "absolute",
    top: "30%",
    left: "70%",
    color: "white",
    fontSize: "30px",
    fontWeight: "bold",
    transform: "translate(-10%, -50%)",
    display: "flex",
    alignItems: "center",
  }}>
    {currentCategory?.ar_term}
  </div>
</div>

      
    </div>
      
      <div className="container">
        <div className={Style.wordOrder}>Words starting with ( {letters[currentIndex]} )</div>
        <div className={Style.wordsStyle}>{lettersAlphanet}</div>
        <div className={Style.tableWords}>
          <div className={Style.onetableItemStyle}>
            <div className={Style.oneWordItemTable}>English</div>
            <div className={`me-4 ${Style.oneWordItemTable}`}>({letters[currentIndex]})</div>
            <div className={Style.oneWordItemTable}>العربية</div>
          </div>
          {sortedWords?.length === 0 ? (
            <div className={`${Style.itemTable} flex-center`}>
              <h5 style={{ color: "#09435a", fontSize: "24px" }} className="m-2 text-center">
                لا توجد كلمات لهذا الحرف
              </h5>
            </div>
          ) : sortedWords?.length === 0 ? (
            <div className={`${Style.itemTable} flex-center`}>
              <h5 style={{ color: "#09435a", fontSize: "24px" }} className="m-2 text-center">
                لا توجد كلمات لهذا الحرف
              </h5>
            </div>
          ) : (
            sortedWordsChunks?.map((item, index) => {
              return <LazyLoading item={item} />;
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default FieldWords;
