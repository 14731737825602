import React from "react";
import Suggestion from "../Components/HomePage/Suggestion/Suggestion";
import Translation from "../Components/HomePage/Translatation/Translation";
import CardHomePage from "../Components/HomePage/CardHomePage/CardHomePage";
import Header from "../Components/HomePage/Header/Header";

const Homepage = () => {
  return (
    <div>
      <Header />
      <CardHomePage />
      <Translation />
      <Suggestion />
    </div>
  );
};

export default Homepage;
