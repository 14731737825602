import React from "react";
import { useData } from "../../../useData";
import { Link } from "react-router-dom";

function Header() {
  const data = useData("get-intro?lang=ar");
  return (
    <div
      style={{
        height: "400px",
        backgroundImage: `url(/assets/home-header.png)`,
      }}
    >
      <div className="header-content">
        <div className="container">
          <div className="texts-content">
            <h2>{data?.en_intro_text}</h2>
            <h3>
              {data?.ar_intro_text?.slice(0, 450)}...{" "}
              <Link style={{ color: "#fff", fontWeight: "700" }} to="/about-site">
                <span>المزيد</span>
              </Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
