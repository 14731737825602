import React from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";
import Sources from "../../sources/Sources";

const AboutSite = () => {
  const about = useSelector((state) => state.webData.about_us?.about_us);
  return <div></div>;
};

export default AboutSite;
