import React from "react";
import { useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import "./profile.css";
import AuthModal from "../Auth/AuthModal";

function MyProfile() {
  const user = useSelector((state) => state.user?.user?.data);
  const [editShow, setEditShow] = React.useState(false);
  return (
    <div className="profile flex-center">
      <div className="container">
        <div className="page-content page-container mb-4" id="page-content">
          <div className="p-3">
            <div className="row container d-flex justify-content-center">
              <div className="">
                <div className="profile-card p-5">
                  <MdEdit
                    onClick={() => setEditShow(true)}
                    className="edit-icon"
                  />
                  <AuthModal
                    show={editShow}
                    setShow={setEditShow}
                    currStack="update-profile"
                  />
                  <div className="row m-l-0 m-r-0">
                    <div className="col-sm-4 bg-c-lite-green user-profile">
                      <div className="card-block text-center text-white">
                        <div className="m-b-25">
                          <div className="flex-center">
                            <img
                              src="https://img.icons8.com/bubbles/100/000000/user.png"
                              className="img-radius"
                              alt="User"
                            />
                          </div>
                        </div>
                        <h6 className="f-w-600">Hembo Tingor</h6>
                        <h4 style={{ color: "#000" }} className="text-center">
                          {user?.name}
                        </h4>
                        {/* <h6 style={{ color: "#000" }} className="text-center">
                          مصمم جرافيك
                        </h6> */}
                        <i className=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="card-block">
                        <h4 className="m-b-20 p-b-5 b-b-default f-w-700 mb-5">
                          معلومات شخصية
                        </h4>
                        <div className="row">
                          <div className="col-sm-6 mb-4">
                            <p className="m-b-10 f-w-600">الاسم</p>
                            <h6 className="text-muted f-w-400">{user?.name}</h6>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <p className="m-b-10 f-w-600">Phone</p>
                            <h6 className="text-muted f-w-400">
                              {user?.mobile}
                            </h6>
                          </div>
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600">البريد الإلكتروني</p>
                            <h6 className="text-muted f-w-400">
                              {user?.email}
                            </h6>
                          </div>
                        </div>

                        <ul className="social-link list-unstyled m-t-40 m-b-10">
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="facebook"
                              data-abc="true"
                            >
                              <i
                                className="mdi mdi-facebook feather icon-facebook facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="twitter"
                              data-abc="true"
                            >
                              <i
                                className="mdi mdi-twitter feather icon-twitter twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="instagram"
                              data-abc="true"
                            >
                              <i
                                className="mdi mdi-instagram feather icon-instagram instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
