import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { USER_LOGIN } from "../../redux/actions/types";
import { BiLoaderAlt } from "react-icons/bi";
import "./auth.css";
import PasswordInput from "../ReusableComponents/PasswordInput";
import toast from "react-hot-toast";
import api from "../../redux/api";
import GoogleAuthButton from "../GoolgeAuth/GoogleAuthButton";
import FacebookAuthButton from "../GoolgeAuth/FacebookAuthButton";
import FacebookAuth from "../GoolgeAuth/FacebookLogin";

function Login({ setCurrentStack, setShow }) {
  const token = useSelector((state) => state.auth?.user?.token);
  const [inputs, setInputs] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState({});
  console.log("ERR", err?.data?.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const callBack = () => {
    setShow(false);
    toast.success("تم تسجيل الدحول بنجاح");
  };

  React.useEffect(() => {
    if (token) {
      setShow(false);
    }
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(reduxPost("auth/login", inputs, callBack, setErr, setLoading, USER_LOGIN));
  };

  const facebookAction = () => {
    api.get(`/auth/login/google`);
  };
  return (
    <div>
      <form className="login" onSubmit={handleSubmit}>
        <label>البريد الإلكتروني</label>
        <input
          autoFocus
          className="form-control login-input"
          placeholder="البريد الإلكتروني"
          type="email"
          name="email"
          onChange={handleChange}
        />
        <PasswordInput
          onChange={handleChange}
          className="mt-2"
          placeholder="كلمة السر"
          name="password"
          err={err}
          min={6}
        />
        <p className="auth-error">{err?.data?.error}</p>
        <h6 className="mb-3 mt-2 forget-password" onClick={() => setCurrentStack("forget-password")}>
          هل نسيت كلمة السر؟
        </h6>
        <button className="login-button">{loading ? <BiLoaderAlt className="loading" /> : "تسجيل الدخول"}</button>
        <p className="dont-have">
          ليس لديك حساب؟{" "}
          <span onClick={() => setCurrentStack("register")} className="sign-up-now">
            سجل الان
          </span>
        </p>

        {/* <button onClick={() => facebookAction()} type="button">
          FACEBOOK LOGIN
        </button> */}
        <GoogleAuthButton />
        <FacebookAuth />
        {/* <FacebookAuthButton /> */}
      </form>
    </div>
  );
}

export default Login;
