import React from "react";
import { FaTwitter, FaFacebookF, FaLinkedin, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function TopHeader() {
  const data = useSelector((state) => state.webData.contact_us);
  return (
    <div className="flex-between p-2 top-header">
      <div className="flex-between">
        <a href={data?.twitter_link} target="_blank">
          <FaTwitter className="top-header-icon" />
        </a>
        <a href={data?.linkedin_link} target="_blank">
          <FaLinkedin className="top-header-icon" />
        </a>
        <a href={data?.youtube_link} target="_blank">
          <FaYoutube className="top-header-icon" />
        </a>
        <a href={data?.facebook_link} target="_blank">
          <FaFacebookF className="top-header-icon" />
        </a>
      </div>
      <Link to="/">
        <img
          className="top-header-logo"
          src="/assets/top-header-logo.png"
          alt="logo"
        />
      </Link>
    </div>
  );
}

export default TopHeader;
