import React from "react";
import { useDispatch } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { BiLoaderAlt } from "react-icons/bi";
import "./auth.css";
import AuthInput from "../ReusableComponents/AuthInput";
import toast from "react-hot-toast";
import axios from "axios";

function RegisterConfirmation({ email, setCurrentStack, setShow }) {
  const [inputs, setInputs] = React.useState({ email });
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState({});
  console.log("ERR", err?.data?.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const callBack = () => {
    setCurrentStack("login");
    toast.success("تم تغيير كلمة السر بنجاح");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(reduxPost("auth/give-me-code-and-password", inputs, callBack, setErr, setLoading, ""));
  };

  const resendCode = () => {
    axios
      .post(`http://eces-glossary.com/public/api/auth/give-me-mail`, {
        email,
      })
      .then(() => toast.success("تم إعادة إرسال الرمز"));
  };
  return (
    <div>
      <form className="login" onSubmit={handleSubmit}>
        <AuthInput onChange={handleChange} placeholder="الكود" name="code" err={err} min={2} />
        <AuthInput onChange={handleChange} placeholder="كلمة السر" name="password" type="password" err={err} min={6} />
        <AuthInput
          onChange={handleChange}
          placeholder="تأكيد كلمة السر"
          name="password_confirmation"
          type="password"
          err={err}
          min={6}
        />

        <button className="login-button mt-3">{loading ? <BiLoaderAlt className="loading" /> : "تأكيد"}</button>
        <p className="dont-have">
          لم يصلك الرمز؟
          <span className="sign-up-now" onClick={() => resendCode()}>
            إعادة إرسال الرمز عبر ابريد الإلكتروني
          </span>
        </p>
      </form>
    </div>
  );
}

export default RegisterConfirmation;
