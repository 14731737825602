import React from "react";

function Devider() {
  return (
    <div className="d-flex devider">
      <div className="devider-long" />
      <div className="devider-short" />
      <div className="devider-short" />
      <div className="devider-short" />
    </div>
  );
}

export default Devider;
