import React from "react";
import { Fade } from "react-reveal";
import CardsSection from "../Components/ContactUs/CardsSection";
import ContactForm from "../Components/ContactUs/ContactForm";
import ContactHeader from "../Components/ContactUs/ContactHeader";
import MyMapComponent from "../Components/ContactUs/ContactMap";

function ContactUsPage() {
  return (
    <div style={{ direction: "rtl" }}>
      <Fade>
        <ContactHeader />
      </Fade>
      <Fade>
        <ContactForm />
      </Fade>
      {/* <Fade>
        <CardsSection />
      </Fade> */}
      {/* <Fade>
        <MyMapComponent />
      </Fade> */}
    </div>
  );
}

export default ContactUsPage;
