import axios from "axios";
import React from "react";
import Modal from "react-modal";
import ForgetPassword from "./ForgetPassword";
import ForgetPasswordChange from "./ForgetPasswordChange";
import Login from "./Login";
import Register from "./Register";
import RegisterConfirmation from "./RegisterConfirmation";
import UpdateProfile from "./UpdateProfile";
import { BsXCircleFill } from "react-icons/bs";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    width: "430px",
    borderRadius: "2px",
    background: "#fff",
    direction: "rtl",
    maxWidth: "95%",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: "1200",
  },
};

function AuthModal({ modalTitle, currStack, show, setShow }) {
  const [currentStack, setCurrentStack] = React.useState(currStack);
  const [regEmail, setRegEmail] = React.useState("");
  const [forgetEmail, setForgetEmail] = React.useState("");
  // axios.post(`asfjaiosfoasfasi`, { sentence: "doctor" }).then((res) => {
  //   // console.log(res);
  // });
  return (
    <div>
      <Modal isOpen={show} onRequestClose={() => setShow(false)} style={customStyles} contentLabel="Example Modal">
        <div className="flex-between">
          <h3>
            {currentStack === "login"
              ? "تسجيل الدخول"
              : currentStack === "register"
              ? "تسجيل"
              : currentStack === "register-confirmation"
              ? "تأكيد التسجيل"
              : currentStack === "forget-password" || currentStack === "forget-password-change"
              ? "إعادة كلمة السر"
              : currentStack === "update-profile"
              ? "تعديل البيانات الشخصية"
              : null}
          </h3>
          <BsXCircleFill className="pointer mb-2" color="#cf34a9" onClick={() => setShow(false)} size={20} />
        </div>
        <hr style={{ marginTop: "10px" }} className="mb-3" />
        {currentStack === "login" ? (
          <Login setCurrentStack={setCurrentStack} setShow={setShow} />
        ) : currentStack === "register" ? (
          <Register setRegEmail={setRegEmail} setCurrentStack={setCurrentStack} setShow={setShow} />
        ) : currentStack === "register-confirmation" ? (
          <RegisterConfirmation email={regEmail} setCurrentStack={setCurrentStack} setShow={setShow} />
        ) : currentStack === "forget-password" ? (
          <ForgetPassword setCurrentStack={setCurrentStack} setEmail={setForgetEmail} />
        ) : currentStack === "forget-password-change" ? (
          <ForgetPasswordChange setCurrentStack={setCurrentStack} email={forgetEmail} />
        ) : currentStack === "update-profile" ? (
          <UpdateProfile setShow={setShow} />
        ) : null}
      </Modal>
    </div>
    // <div style={{ direction: "rtl" }}>
    //   <Modal
    //     style={{
    //       direction: "rtl",
    //       zIndex: 2000000000,
    //       maxWidth: "100%",
    //     }}
    //     show={show}
    //     onHide={() => setShow(false)}
    //   >
    //     <Modal.Header>
    //       <Modal.Title>
    //         {currentStack === "login"
    //           ? "تسجيل الدخول"
    //           : currentStack === "register"
    //           ? "تسجيل"
    //           : currentStack === "register-confirmation"
    //           ? "تأكيد التسجيل"
    //           : currentStack === "forget-password" ||
    //             currentStack === "forget-password-change"
    //           ? "إعادة كلمة السر"
    //           : currentStack === "update-profile"
    //           ? "تعديل البيانات الشخصية"
    //           : null}
    //       </Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       {currentStack === "login" ? (
    //         <Login setCurrentStack={setCurrentStack} setShow={setShow} />
    //       ) : currentStack === "register" ? (
    //         <Register
    //           setRegEmail={setRegEmail}
    //           setCurrentStack={setCurrentStack}
    //           setShow={setShow}
    //         />
    //       ) : currentStack === "register-confirmation" ? (
    //         <RegisterConfirmation
    //           email={regEmail}
    //           setCurrentStack={setCurrentStack}
    //           setShow={setShow}
    //         />
    //       ) : currentStack === "forget-password" ? (
    //         <ForgetPassword
    //           setCurrentStack={setCurrentStack}
    //           setEmail={setForgetEmail}
    //         />
    //       ) : currentStack === "forget-password-change" ? (
    //         <ForgetPasswordChange
    //           setCurrentStack={setCurrentStack}
    //           email={forgetEmail}
    //         />
    //       ) : currentStack === "update-profile" ? (
    //         <UpdateProfile setShow={setShow} />
    //       ) : null}
    //     </Modal.Body>
    //   </Modal>
    // </div>
  );
}
export default AuthModal;
