import React from "react";

function InputsShimmers() {
  return (
    <div>
      <div class="container">
        <article>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="shimmer"></div>
        </article>
      </div>
    </div>
  );
}

export default InputsShimmers;
