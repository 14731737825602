import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function AppModal({ modalTitle, modalBody, show, setShow }) {
  const handleClose = () => setShow(false);
  return (
    <div style={{ direction: "rtl" }}>
      <Modal style={{ direction: "rtl", zIndex: 2000000000 }} show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
    </div>
  );
}
export default AppModal;
