import React from "react";

function SurveyRadio({ ques, handleChange }) {
  const [isOther, setIsOther] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const handleInsideChange = (e) => {
    setInputValue("");
    if (e.target.value === "other") {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  };
  return (
    <>
      <label className="survey-label mb-1">{ques?.label}</label>
      {ques?.options?.map((opt, index) => {
        return (
          <div key={index} className="mb-1">
            <input
              onChange={(e) => {
                handleChange(e, ques);
                handleInsideChange(e);
              }}
              value={opt?.id}
              required={ques?.mandatory === 1}
              name={`for${ques.id}`}
              type="radio"
              style={{ marginLeft: "10px" }}
            />
            <label className="survey-label">{opt?.option}</label>
          </div>
        );
      })}
      <div className="d-flex align-items-center">
        <div>
          <input
            onChange={(e) => {
              handleChange(e, ques);
              handleInsideChange(e);
            }}
            value="other"
            required={ques?.mandatory === 1}
            name={`for${ques.id}`}
            type="radio"
            style={{ marginLeft: "10px" }}
          />
          <label className="survey-label">سبب اخر</label>
        </div>
        <div>
          <input
            style={{ marginRight: "10px" }}
            className="form-control"
            placeholder="سبب اخر"
            onChange={(e) => {
              handleChange(e, ques);
              setInputValue(e.target.value);
            }}
            disabled={!isOther}
            required={isOther}
            value={inputValue}
          />
        </div>
      </div>
    </>
  );
}

export default SurveyRadio;
