import React from "react";
import { useSelector } from "react-redux";
import AboutSite from "../Components/About/AboutSite/AboutSite";
import Message from "../Components/About/Message/Message";
import Fade from "react-reveal/Fade";
import Sources from "../Components/sources/Sources";
import { useLocation } from "react-router-dom";
const AboutSitePage = () => {
  const content = useSelector((state) => state.webData.about_us?.content);
  const { hash } = useLocation();
  const resourcesRef = React.useRef();
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  React.useEffect(() => {
    console.log("PATHNAME", hash);
    setTimeout(() => {
      if (hash.includes("resources")) {
        scrollToRef(resourcesRef);
      }
    }, 5);
  }, []);

  return (
    <div>
      <AboutSite />

      <Fade>
        <div className="flex-center">
          <h1 className="text-center mt-4 custom-h1">عن الموقع</h1>
        </div>
        <div style={{ direction: "rtl" }}>
          {content?.map((item, index) => {
            return <Message key={index} item={item} />;
          })}
        </div>
      </Fade>
      <div ref={resourcesRef}>
        <Fade>
          <Sources />
        </Fade>
      </div>
      {/* <WorkTeam /> */}
    </div>
  );
};

export default AboutSitePage;
