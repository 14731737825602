export const GET_USER_DATA = "GET_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const USER_LOGIN = "USER_LOGIN";
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_MY_DATA = "GET_MY_DATA";
export const GET_SLIDERS = "GET_SLIDERS";
export const GET_CONTACT = "GET_CONTACT";
export const GET_ABOUT = "GET_ABOUT";
export const GET_CATEGORIES = "GET_CATEGORIES";
