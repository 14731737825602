import axios from "axios";
import React from "react";

export const useData = (endPoint) => {
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      const response = await axios.get(`https://eces-glossary.com/public/api/${endPoint}`);
      setData(response.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};
