import React from "react";
import FieldsHeader from "../BusinessTerms/FieldsHeader";
import style from "./sources.module.css";
// import { useData } from "../../useData";
import { useSelector } from "react-redux";
import { imgUrl } from "../../variables";
import { Fade } from "react-reveal";
const Sources = ({ title }) => {
  const {
    headTextOne,
    // sources,
    contentList,
    sourceImgOne,
    sourceImgTwo,
    sourceOne,
  } = style;
  // const showdata = useData("get-about-site");
  //return showdata?.sources?.map((item, index) => {
  const sources = useSelector((state) => state.webData.about_us?.sources);
  const width = window.innerWidth;
  return (
    <div>
      <div className="flex-center">
        <h1 className="text-center mt-4 mb-5 custom-h1">المصادر</h1>
      </div>
      <div style={{ direction: "rtl" }} className={sources}>
        <div cl className="container mt-4 mb-5">
          {sources?.map((item, index) => {
            return <div dangerouslySetInnerHTML={{ __html: item?.source_content }} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Sources;
/*
<div className={style.itemList}>
                          (OECD) منظمة التعاون والتنمية الاقتصادية
                        </div>
                        <div className={style.itemList}>
                          (IMF) صندوق النقد الدولي
                        </div>
                        <div className={style.itemList}>(FinDev) بوابة </div>
                        <div className={style.itemList}>
                          (ILO) موقع منظمة العمل الدولية
                        </div>
                        <div className={style.itemList}>(Cognizant) موقع </div>
                        <div className={style.itemList}>
                          منظمة الملكية الفكرية العالمية (الويبو)
                        </div>
              */
