import React from "react";
import { auth, provider } from "./config";
import { signInWithPopup } from "firebase/auth";
import { useDispatch } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { GOOGLE_LOGIN } from "../../redux/actions/types";
import { FcGoogle } from "react-icons/fc";

function GoogleAuthButton() {
  const [email, setEmail] = React.useState();
  const [name, setName] = React.useState();
  const [phone, setPhone] = React.useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState();
  const callBack = () => {};
  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        const inputs = {
          name: data.user.displayName,
          email: data.user.email,
        };
        console.log("FIREBASE ERROR", data);
        dispatch(reduxPost("auth/socialLogin", inputs, callBack, () => {}, setLoading, GOOGLE_LOGIN));
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <button className="google-button" type="button" onClick={() => handleClick()}>
        <span style={{ marginRight: "20px" }}>تسجيل الدخول باستخدام جوجل</span> <FcGoogle className="icon" size={40} />
      </button>
    </div>
  );
}

export default GoogleAuthButton;
