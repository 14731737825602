import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { GET_MY_DATA, USER_LOGIN } from "../../redux/actions/types";
import { BiLoaderAlt } from "react-icons/bi";
import "./auth.css";
import AuthInput from "../ReusableComponents/AuthInput";

function UpdateProfile({ setShow }) {
  const user = useSelector((state) => state.user?.user?.data);
  const token = useSelector((state) => state.auth?.user?.token);
  const [inputs, setInputs] = React.useState({
    token,
    name: user?.name,
    email: user?.email,
    mobile: user?.mobile,
  });
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState({});
  console.log("ERR", err?.data?.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const callBack = () => {
    dispatch(
      reduxPost(
        "auth/user-profile",
        { token },
        () => {},
        () => {},
        () => {},
        GET_MY_DATA
      )
    );
    setShow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      reduxPost("auth/update-profile", inputs, callBack, setErr, setLoading, "")
    );
  };
  return (
    <div>
      <form className="login" onSubmit={handleSubmit}>
        <AuthInput
          onChange={handleChange}
          placeholder="الاسم"
          name="name"
          err={err}
          min={2}
          defaultValue={user?.name}
        />
        <AuthInput
          onChange={handleChange}
          className="mt-2"
          placeholder="البريد الإلكتروني"
          name="email"
          type="email"
          err={err}
          defaultValue={user?.email}
        />
        <AuthInput
          onChange={handleChange}
          className="mt-2"
          placeholder="رقم الهاتف"
          name="mobile"
          err={err}
          min={2}
          defaultValue={user?.mobile}
        />
        <button className="login-button mt-3">
          {loading ? <BiLoaderAlt className="loading" /> : "تأكيد التعديل"}
        </button>
      </form>
    </div>
  );
}

export default UpdateProfile;
