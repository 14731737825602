/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import "./Translation.css";

import { useData } from "../../../useData";
import { BiLoaderAlt } from "react-icons/bi";
import api from "../../../redux/api";
import { Flip, Bounce, Zoom, Fade, Slide } from "react-reveal";

const Translation = () => {
  const [language, setLanguage] = useState("english");
  const [focused, setFocused] = React.useState(false);
  const [input, setInputs] = useState({});
  const [result, setResult] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const showdata = useData("get-translation-intro?lang=ar");
  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleClick = (e) => {
    setLanguage(language === "arabic" ? "english" : "arabic");
  };
  const callBack = (res) => {
    console.log(res);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .get(`get-translation?sentence=${input.sentence}`)
      .then((res) => {
        setLoading(false);
        setErr(false);
        {
          language === "arabic" ? setResult(res?.data?.en_term) : setResult(res?.data?.ar_term);
        }
        //setResult(res?.data?.ar_term);
      })
      .catch((err) => {
        setLoading(false);
        setErr(true);
      });
  };
  return showdata?.translationIntro?.map((item, index) => {
    //console.log(showdata);
    return (
      <div className="translation-wrapper">
        <div className="container">
          <div className="translation">
            <Fade top>
              <h2>{item?.ar_title}</h2>
            </Fade>
            <Fade bottom>
              {/* <p>
                <div dangerouslySetInnerHTML={{ __html: item?.ar_desc }} />
              </p> */} 

<p className="m-0 p-0"> أكتب المصطلح المراد ترجمته في الخانة الانجليزية<p className="m-0 p-0" style={{display:"inline-block"}}>"Translate" ثم اضغط،</p>للحصول على المرادف باللغة العربية</p> 
               
            </Fade>
           
            <div className="container mt-5">
              <div className="flex-between textareas-wrapper">
                <div style={{ direction: "rtl" }}>
                  <div className="flex-between">
                    <div />
                    <div>
                      <Slide left>
                        <button
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#cf34a9",
                          }}
                          className="show-translate-btn"
                        >
                          {language === "english" ? "English" : " العربية"}
                        </button>
                      </Slide>
                      {/* <Slide right>
                        <button
                          onClick={() => setLanguage("arabic")}
                          className="write-translate-btn"
                          style={{
                            backgroundColor: language === "english" ? "#ffffff" : "#cf34a9",
                            color: language === "english" ? "#cf34a9" : "#ffffff",
                          }}
                        >
                          العربية
                        </button>
                      </Slide>
                      <Slide left>
                        <button
                          onClick={() => setLanguage("english")}
                          style={{
                            backgroundColor: language === "arabic" ? "#ffffff" : "#cf34a9",
                            color: language === "arabic" ? "#cf34a9" : "#ffffff",
                          }}
                          className="write-translate-btn"
                        >
                          English
                        </button>
                      </Slide> */}
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div
                      style={{
                        border: "2px solid #1fcabf",
                        boxSizing: "border-box",
                      }}
                    >
                      <textarea
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onChange={handleChange}
                        style={{
                          display: "block",
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                        name="sentence"
                        rows="10"
                        cols="50"
                        placeholder={language === "arabic" ? "أدخل النص الخاص بك هنا" : "Enter your text here"}
                        className="write-translate-textarea"
                        maxLength="160"
                      />
                      <div
                        className="flex-between"
                        style={{
                          backgroundColor: "#fff",
                          height: "50px",
                          padding: "20px",
                        }}
                      >
                        <Zoom>
                          <button onClick={handleSubmit} className="translate-button">
                            {loading ? (
                              <BiLoaderAlt className="loading" />
                            ) : language === "arabic" ? (
                              "ترجمة"
                            ) : (
                              "Translate"
                            )}
                          </button>
                          <span>{input?.sentence ? input?.sentence?.length : 0}/160</span>
                        </Zoom>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <TbArrowsLeftRight size="30" className="mt-5 exchange-icon" onClick={handleClick} /> */}
                <div>
                  <div className="flex-between">
                    <div />
                    <Slide left>
                      <button
                        style={{
                          backgroundColor: "#ffffff",
                          color: "#cf34a9",
                        }}
                        className="show-translate-btn"
                      >
                        {language === "arabic" ? "English" : " العربية"}
                      </button>
                    </Slide>
                  </div>
                  <form>
                    <textarea
                      style={{
                        border: "2px solid #1fcabf",
                        display: "block",
                        height: "250px",
                        marginTop: "-5px",
                        color: err ? "red" : "#000",
                      }}
                      value={err ? "برجاء إدخال نص صحيح" : result}
                      name="textarea"
                      rows="10"
                      cols="50"
                      className="show-translate-textarea"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "60px" }} />
          </div>
        </div>
      </div>
    );
  });
};

export default Translation;
/*<BsArrowLeftRight size="30" className="mt-5" />
import { BsArrowLeftRight } from "react-icons/bs";
*/
