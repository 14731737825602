import React from "react";
import toast from "react-hot-toast";
import { BiLoaderAlt } from "react-icons/bi";
import api from "../../redux/api";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import AuthModal from "../Auth/AuthModal";

function ContactForm() {
  const token = useSelector((state) => state.auth?.user?.token);
  const [inputs, setInputs] = React.useState({});
  const [submited, setSubmited] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loginShow, setLoginShow] = React.useState(false);
  const [registerShow, setRegisterShow] = React.useState(false);
  const [err, setErr] = React.useState();
  const formRef = React.useRef();
  const submitedClass = submited ? "submsited" : "";
  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reChecked != null) {
      setSubmited(true);
      setLoading(true);
      api
        .post(`contact-us`, { ...inputs, token })
        .then(() => {
          formRef.current.reset();
          setLoading(false);
          toast.success(<h3 style={{ textAlign: "right" }}>تم الإرسال، شكراً لاهتمامك</h3>);
        })
        .catch((errors) => {
          setLoading(false);
          setErr(errors.response?.data);
        });
    }
  };

  const [reChecked, setReChecked] = React.useState(null);
  function handleRecaptchaChange(value) {
    setReChecked(value);
    console.log("RE VALUE", value);
  }
  console.log("ERR", err);

  return (
    <div className="container flex-center">
      <AuthModal show={loginShow} setShow={setLoginShow} currStack="login" />
      <AuthModal show={registerShow} setShow={setRegisterShow} currStack="register" />
      <div className="contact-form">
        <h2 className="text-center mt-5">يسعدنا تواصلك معنا وتقديم مقترحك </h2>
       
        {!token && (
          <div style={{ marginBottom: "20px" }}>
            {/* <h6 className="text-center">لكي تتمكن من إرسال مقترحك، يرجى تسجيل الدخول أولا</h6> */}
            {/* <h6 className="text-center">او إنشاء حساب جديد إن كانت هذه أول زيارة لك</h6> */}
            {/* <div className="flex-center contact-auth">
              <div className="account-user mt-3">
                <button onClick={() => setRegisterShow(true)}>التسجيل</button>
                <button onClick={() => setLoginShow(true)}>تسجيل دخول</button>
              </div>
            </div> */}
          </div>
        )}

        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="row">
            {/* <div className="col-md-6">
              <input
                required
                onChange={handleChange}
                name="name"
                placeholder="الاسم"
                className={`form-control ${submitedClass}`}
              />
              <p className="contact-err">{err?.errors?.name?.[0]}</p>
            </div>
            <div className="col-md-6">
              <input
                required
                onChange={handleChange}
                name="email"
                placeholder="البريد الإلكتروني"
                className={`form-control ${submitedClass}`}
                type="email"
              />
              <p className="contact-err">{err?.errors?.email?.[0]}</p>
            </div>
            <div className="col-md-6">
              <input
                required
                onChange={handleChange}
                name="phone"
                placeholder="الهاتف"
                className={`form-control ${submitedClass}`}
                type="number"
              />
              <p className="contact-err">{err?.errors?.phone?.[0]}</p>
            </div> */}
            <div className="col-md-12">
              <input
                required
                onChange={handleChange}
                name="user_email"
                placeholder="البريد الإلكتروني"
                className={`form-control ${submitedClass}`}
                type="email"
              />
              <p className="contact-err">{err?.errors?.email?.[0]}</p>
            </div>
            <div className="col-md-12">
              <input
                required
                onChange={handleChange}
                name="subject"
                placeholder="الموضوع"
                className={`form-control ${submitedClass}`}
              />
              <p className="contact-err">{err?.errors?.subject?.[0]}</p>
            </div>
            <div className="col-md-12 flex-center">
              <textarea
                required
                onChange={handleChange}
                name="message"
                placeholder="الرسالة"
                rows={5}
                className={`form-control ${submitedClass}`}
              />
              <p className="contact-err">{err?.errors?.message?.[0]}</p>
            </div>
          </div>
          <div className="flex-center">
            <ReCAPTCHA sitekey="6Lch5UMkAAAAAGKEDbehOJsqZnI64-rT-p_rX6ef" onChange={handleRecaptchaChange} />
          </div>
          <div className="flex-center">
            <button style={{ width: "160px" }} className="mb-5 mt-3">
              {loading ? <BiLoaderAlt className="loading" /> : "إرسال"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
