import React from "react";

function AuthInput({ onChange, placeholder, min, type, className, name, err, style, defaultValue }) {
  console.log("Err Inside", err);
  return (
    <div>
      <label className={className}>{placeholder}</label>
      <input
        className={`form-control login-input`}
        placeholder={placeholder}
        type={type}
        min={min}
        name={name}
        onChange={onChange}
        style={style}
        defaultValue={defaultValue ? defaultValue : ""}
      />
      {err?.data?.error?.[name]?.map((item, index) => {
        return (
          <p className="auth-error" key={index}>
            {item}
          </p>
        );
      })}
    </div>
  );
}

export default AuthInput;
