import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { BiLoaderAlt } from "react-icons/bi";
import { useData } from "../useData";
import InputsShimmers from "./InputsShimmers";
import SurveyRadio from "./SurveyRadio";

function Survey({ setShow, getUser }) {
  const survey = useData("questions");
  const answersVal = survey?.questions?.map((item, index) => {
    return {
      question_id: item?.id,
      for: "",
    };
  });
  const [answers, setAnswers] = React.useState([]);
  console.log("THE ANSWERS", answers);

  const [loading, setLoading] = React.useState(false);
  const body = { survey: answers };
  const postSurvey = (e) => {
    setLoading(true);
    e.preventDefault();
    axios.post(`https://eces-glossary.com/public/api/survey`, body).then(() => {
      setLoading(false);
      setShow(false);
      getUser();
      toast.success(<h3 style={{ textAlign: "right" }}>تم ملء الاستبيان بنجاح</h3>);
      localStorage.setItem("survey", "done");
    });
  };

  const handleChange = (e, ques) => {
    //
    if (ques.question_type === 0) {
      const quesData = { question_id: ques.id, for: e.target.value };
      const quesIndex = answers.findIndex((q) => q.question_id === ques.id);
      if (quesIndex < 0) {
        setAnswers(answers.concat([quesData]));
      } else {
        const newAnswers = [...answers];
        newAnswers.splice(quesIndex, 1, quesData);
        setAnswers(newAnswers);
      }

      console.log("QUES DATA", quesData);
      return;
    }
    //
    if (ques.question_type === 1) {
      if (e.target.value !== "") {
        const choosenOption = ques.options.find((option) => option.id == e.target.value);
        const quesData = { question_id: ques.id, option_id: e.target.value, for: choosenOption.option };
        const quesIndex = answers.findIndex((q) => q.question_id === ques.id);
        if (quesIndex < 0) {
          setAnswers(answers.concat([quesData]));
        } else {
          const newAnswers = [...answers];
          newAnswers.splice(quesIndex, 1, quesData);
          setAnswers(newAnswers);
        }
        return;
      } else {
        const quesIndex = answers.findIndex((q) => q.question_id === ques.id);
        const newAnswers = [...answers];
        newAnswers.splice(quesIndex, 1);
        setAnswers(newAnswers);
      }
    }
    //
    if (ques.question_type === 2) {
      let quesData = { question_id: ques.id };
      const choosenOption = ques.options.find((option) => option.id == e.target.value);
      if (choosenOption) {
        quesData = { ...quesData, option_id: e.target.value, for: choosenOption.option };
      } else {
        quesData = { ...quesData, for: e.target.value };
      }
      const quesIndex = answers.findIndex((q) => q.question_id === ques.id);
      if (quesIndex < 0) {
        setAnswers(answers.concat([quesData]));
      } else {
        const newAnswers = [...answers];
        newAnswers.splice(quesIndex, 1, quesData);
        setAnswers(newAnswers);
      }
      return;
    }
  };
  return (
    <form onSubmit={postSurvey}>
      {survey ? (
        survey?.map((ques, index) => {
          return ques.question_type == 0 ? (
            // Text Case
            <div>
              <label className="survey-label mb-1">{ques?.label}</label>
              <input
                className="form-control mb-3"
                placeholder={ques?.label}
                required={ques?.mandatory === 1}
                onChange={(e) => handleChange(e, ques)}
              />
            </div>
          ) : ques?.question_type === 1 ? (
            <div>
              <label className="survey-label mb-1">{ques?.label}</label>
              <select
                onChange={(e) => handleChange(e, ques)}
                required={ques?.mandatory === 1}
                className="form-select mb-3"
              >
                <option value="">اختر</option>
                {ques?.options?.map((opt, index) => {
                  return (
                    <option key={index} value={opt?.id} id={opt?.option}>
                      {opt?.option}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            // Radio Case
            <SurveyRadio ques={ques} handleChange={handleChange} />
          );
        })
      ) : (
        <InputsShimmers />
      )}
      <button className="login-button mt-3 mb-1">{loading ? <BiLoaderAlt className="loading" /> : "تأكيد"}</button>
    </form>
  );
}

export default Survey;

// First Case (Text Input)
// {
//   question_id: 33, // Question id
//   for: "Egypt, El-Sheikh zayed, 11 state" // Answer as a Text
// }

// Second Case (Dropdown)
// {
//   question_id: 34, // Question id
//   for: "option2", // Answer as a Text
//   option_id: 42 // Answer as id
// },

// Radio Buttons (Without Other Choice)
// {
//   question_id: 37,
//   for": "egypt",
//   option_id: 50
// }

// Radio Buttons (Other Choice)
// {
//   question_id: 33, // Question id
//   for: "Egypt, El-Sheikh zayed, 11 state" // Answer as a Text
// }
