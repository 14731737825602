import React from "react";
import "./Footer.css";
import { MdCall, MdLocationOn, MdMail, MdMap, MdPerson } from "react-icons/md";
import { FaFacebookF, FaLinkedin, FaYoutube, FaXTwitter ,FaWhatsapp} from "react-icons/fa6";
import { HiBuildingOffice2 } from "react-icons/hi2";
import Devider from "./Devider";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  const categories = useSelector((state) => state.webData?.categories);
  const data = useSelector((state) => state.webData.contact_us);
  return (
    <div
      style={{
        backgroundImage: "url(/assets/footer-bg.jpg)",
        backgroundSize: "cover",
      }}
    >
      <div className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3 d-flex justify-content-center">
              <img className="footer-logo" src="/assets/eces.png" alt="logo" />
            </div>
            <div className="col-md-3">
              <h6>تواصل معنا</h6>
              <Devider />
              <p>
                <MdLocationOn className="footer-icon" /> {data?.company_location}
              </p>
              <p style={{ direction: "ltr" }}>
                <a style={{ color: "#fff" }} href={`tel:${data?.phone}`}>
                  {data?.phone}
                </a>{" "}
                <MdCall className="footer-icon" />
              </p>
              {/* <p style={{ direction: "ltr" }}>
                <a style={{ color: "#fff" }} href={`tel:${data?.mobile}`}>
                  {data?.mobile}
                </a>{" "}
                <FaFax className="footer-icon" />
              </p> */}
              <p>
                <MdMail className="footer-icon" /> {data?.email}
              </p>
            </div>
            <div className="col-md-3">
              <h6>روابط هامة</h6>
              <Devider />
              <Link to="/about-site">
                <p>عن الموقع والمصادر</p>
              </Link>
              <Link to="/contact-us">
                <p>تواصل معنا</p>
              </Link>
              <a href="https://eces.org.eg" >
                <p>عودة للموقع الرئيسي</p>
              </a>
              <Link to="/site-map">
                <p>خريطة الموقع</p>
              </Link>
            </div>

            <div className="col-md-3">
              <h6>المجالات</h6>
              <Devider />
              {categories?.map((item, index) => {
                return (
                  <Link to={`/expressions/${item?.slug}`} key={index}>
                    <p>{item?.ar_term}</p>
                  </Link>
                );
              })}
              <div style={{ direction: "ltr" }} className="flex-between footer-icons">
               
                <a href={data?.facebook_link} target="_blank">
                  <FaFacebookF className="footer-social-icon" />
                </a>   

                <a href={data?.twitter_link} target="_blank">
                  <FaXTwitter className="footer-social-icon" />
                </a> 

                <a href={data?.linkedin_link} target="_blank">
                  <FaLinkedin className="footer-social-icon" />
                </a>

                <a href="https://www.whatsapp.com/channel/0029VaG1eQ377qVVC5Pt2u1W" target="_blak">
                <img src="/assets/icon10.png" className="whats-icon2"></img>
                </a>

                <a href={data?.youtube_link} target="_blank">
                  <FaYoutube className="footer-social-icon" />
                </a>
              </div>
            </div>
            <h5 style={{ color: "white" }}>
              © {new Date().getFullYear()} 
              - المركز المصري للدراسات الاقتصادية (ECES) جميع الحقوق محفوظة
            </h5>   

            <br/>

            <p style={{ color: "white",fontSize:"12px",marginBottom:"0px" ,paddingBottom:"10px" ,paddingTop:"10px"}}>
            لا يجوز إعادة إنتاج أي جزء من هذا الإصدار أو حفظه في نظام لاسترجاع المعلومات أو نقله بأي شكل أو بأي وسيلة سواء كانت ميكانيكية أو إلكترونية أو من خلال النسخ أو التسجيل أو غير ذلك دون الإشارة إلى المركز المصري للدراسات الاقتصادية كمصدر له.
            </p> 
            

            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
