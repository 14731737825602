import React from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useSelector } from "react-redux";

function Sitemap() {
  const categories = useSelector((state) => state.webData?.categories);
  const [showFields, setShowFields] = React.useState(false);
  return (
    <div>
      <div className="contact-header-wrapper">
        <div className="contact-layer flex-center">
          <h1>خريطة الموقع</h1>
        </div>
      </div>

      <div className="container">
        <div className="sitemap-link">المعجم الاقتصادي</div>
        <div className="sitemap-link">عن الموقع والمصادر</div>
        <div
          onClick={() => setShowFields(!showFields)}
          className="sitemap-link flex-between"
          style={{ marginBottom: "0px" }}
        >
          <span>المجالات</span>
          <span>
            <MdArrowDropDown />
          </span>
        </div>
        {/* <div className="sitemap-sublink">Sub link</div>
      <div className="sitemap-sublink">Sub Link</div> */}
        <div
          style={
            showFields
              ? { height: "285px", transition: "0.3s ease" }
              : {
                  height: "0.000001px",
                  overflow: "hidden",
                  transition: "0.3s ease",
                }
          }
        >
          {categories?.map((item, index) => {
            return (
              <div key={index} className="sitemap-sublink">
                {item?.ar_term}
              </div>
            );
          })}
        </div>
        <div className="sitemap-link">المصادر</div>
        <div className="sitemap-link">تواصل معنا</div>
        <div className="sitemap-link">عودة للموقع الرئيسي</div>
      </div>
    </div>
  );
}

export default Sitemap;
