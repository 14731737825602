import React from "react";

const Pagnation = ({ children }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const childrenArray = React.Children.toArray(children);
  const handleNext = () => {
    if (currentIndex !== children.length - 1) {
      setCurrentIndex(currentIndex + 1);
      window.scrollTo(0, 0);
    }
  };
  const handleBack = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
      window.scrollTo(0, 0);
    }
  };
  return (
    <div>
      {childrenArray[currentIndex]}
      <div className="container flex-between mt-4">
        <button onClick={handleBack} className="next-button">
          Back
        </button>
        <button className="next-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};
export default Pagnation;
