import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useInView } from "react-intersection-observer";
import Style from "./fieldWords.module.css";

function LazyLoading({ item }) {
  const [load, setLoad] = React.useState(true);

  const { ref, inView, entry } = useInView();
  console.log(inView);

  React.useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setLoad(false);
      }, []);
    }
  }, [inView]);
  console.log("MY ITEM IS", item);
  return (
    <div ref={ref}>
      {load ? (
        <div className="flex-center" style={{ height: "50px" }}>
          <BiLoaderAlt className="loading" color="#09435a" size={50} />
        </div>
      ) : (
        item?.map((childItem, index) => {
          console.log(index, index % 2 !== 0, childItem?.ar_term, "CHECKKK");
          return (
            <div
              key={index}
              className={Style.itemTable}
              style={{ background: index % 2 !== 0 ? "rgba(19, 169, 197, 0.5)" : "" }}
            >
              <div className={Style.itemWordEnglish}>{childItem?.en_term}</div>
              <div className={`text-end ${Style.itemWord}`}>{childItem?.ar_term}</div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default LazyLoading;
