import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Login from "../../Auth/Login";
import AppModal from "../../ReusableComponents/AppModal";
import { BsPersonFill } from "react-icons/bs";
import { emptyAction } from "../../../redux/actions/reusableActions";
import { LOGOUT } from "../../../redux/actions/types";
import AuthModal from "../../Auth/AuthModal";
import toast from "react-hot-toast";
import TopHeader from "./TopHeader";
import { FaXTwitter, FaFacebookF, FaLinkedin, FaYoutube , FaWhatsapp} from "react-icons/fa6";

const Navbar = ({ showNav }) => {
  const navigate = useNavigate();
  const [loginShow, setLoginShow] = React.useState(false);
  const [registerShow, setRegisterShow] = React.useState(false);
  const token = useSelector((state) => state.auth?.user?.token);
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.webData?.categories);
  const { pathname } = useLocation();
  const data = useSelector((state) => state.webData.contact_us);

  return (
    <div className="navbar">
      <div className="flex-between p-2 top-header">
        <div className="flex-between">
        <a href={data?.facebook_link} target="_blank">
                  <FaFacebookF className="top-header-icon" />
                </a>   

                <a href={data?.twitter_link} target="_blank">
                  <FaXTwitter className="top-header-icon" />
                </a> 

                <a href={data?.linkedin_link} target="_blank">
                  <FaLinkedin className="top-header-icon" />
                </a>

                <a href="https://www.whatsapp.com/channel/0029VaG1eQ377qVVC5Pt2u1W" target="_blak">
                  <img src="/assets/icon8.png" className="whats-icon"></img>
                </a>

                <a href={data?.youtube_link} target="_blank">
                  <FaYoutube className="top-header-icon" />
                </a>
          <div style={{ marginLeft: "20px" }} className="navbar-wrapper">
            {token ? (
              <div>
                {/* <NavDropdown
                title={<BsPersonFill className="mb-2" size={40} />}
                className="style-nav-dropdown style-link"
              >
                <NavDropdown.Item
                  className="style-navdropdown-item"
                  style={{ textAlign: "right" }}
                  onClick={() => navigate("/my-profile")}
                >
                  الملف الشخصي
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    dispatch(emptyAction(LOGOUT));
                    toast.success("تم تسجيل الخروج بنجاح");
                  }}
                  className="style-navdropdown-item"
                  style={{ textAlign: "right" }}
                >
                  تسجيل الخروج
                </NavDropdown.Item>
              </NavDropdown> */}
              </div>
            ) : (
              <div className="account-user mt-3">
                {/* <button onClick={() => setRegisterShow(true)}>التسجيل</button>
                <button onClick={() => setLoginShow(true)}>تسجيل دخول</button> */}
              </div>
            )}
          </div>
        </div>
        <Link to="/">
          <img className="top-header-logo" src="/assets/top-header-logo.png" alt="logo" />
        </Link>
      </div>
      {/* <TopHeader /> */}
      <AuthModal show={loginShow} setShow={setLoginShow} currStack="login" />
      <AuthModal show={registerShow} setShow={setRegisterShow} currStack="register" />
      <div className="container-fluid">
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="links">
            <div>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div className={`style-link ${pathname === "/" ? "active" : ""}`}>المعجم الاقتصادي</div>
              </Link>
            </div>
            <div>
              <Link to="/about-site" style={{ textDecoration: "none" }}>
                <div className={`style-link ${pathname.includes("about") ? "active" : ""}`}>عن الموقع والمصادر</div>
              </Link>
            </div>
            <div>
              <NavDropdown
                title="المجالات"
                // className="style-nav-dropdown style-link"
                className={`style-link style-nav-dropdown ${pathname.includes("expressions") ? "active" : ""}`}
              >
                {categories?.map((item, index) => {
                  return (
                    <NavDropdown.Item
                      key={index}
                      onClick={() => {
                        navigate(`/expressions/${item?.slug}`);
                      }}
                      className="style-navdropdown-item"
                      style={{ textAlign: "right" }}
                    >
                      {item?.ar_term}
                    </NavDropdown.Item>
                  );
                })}
                {/* <NavDropdown.Item
                  className="style-navdropdown-item"
                  style={{ textAlign: "right" }}
                  onClick={() => navigate("/contact-us")}
                >
                  تواصل معنا
                </NavDropdown.Item> */}
              </NavDropdown>
            </div>

            {/* <div>
              <Link to="/resources" style={{ textDecoration: "none" }}>
                <div
                  className={`style-link ${
                    pathname.includes("resources") ? "active" : ""
                  }`}
                >
                  المصادر
                </div>
              </Link>
            </div> */}
            <div>
              <Link to="/contact-us" style={{ textDecoration: "none" }}>
                <div className={`style-link ${pathname.includes("contact") ? "active" : ""}`}>تواصل معنا</div>
              </Link>
            </div>
            <div>
              <a href="https://eces.org.eg">
                <div className={`style-link ${pathname === "/safas" ? "active" : ""}`}>عودة للموقع الرئيسي</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
