import React from "react";
import { useDispatch } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { BiLoaderAlt } from "react-icons/bi";
import "./auth.css";
import AuthInput from "../ReusableComponents/AuthInput";
import toast from "react-hot-toast";

function ForgetPassword({ setEmail, setCurrentStack }) {
  const [inputs, setInputs] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState({});
  console.log("ERR", err?.data?.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const callBack = () => {
    setEmail(inputs.email);
    setCurrentStack("forget-password-change");
    toast.success("تم إرسال الكود عبر البريد الإلكتروني");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(reduxPost("auth/give-me-mail", inputs, callBack, setErr, setLoading, ""));
  };
  return (
    <div>
      <form className="login" onSubmit={handleSubmit}>
        <AuthInput
          onChange={handleChange}
          placeholder="البريد الإلكتروني"
          name="email"
          type="email"
          err={err}
          min={2}
        />
        <p className="auth-error">{err?.data?.message}</p>
        <button className="login-button mt-3">{loading ? <BiLoaderAlt className="loading" /> : "تأكيد"}</button>
        <p className="dont-have">
          هل لديك حساب بالفعل؟
          <span className="sign-up-now" onClick={() => setCurrentStack("login")}>
            تسجيل الدخول الان
          </span>
        </p>
      </form>
    </div>
  );
}

export default ForgetPassword;
