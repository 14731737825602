import React from "react";
import "./Suggestion.css";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { useData } from "../../../useData";
const Suggestion = () => {
  const data = useData("get-suggestions-intro");
  return (
    <div className="sugg-section">
      <div className="sugggest-one flex-between" style={{ flexDirection: "row", width: "100%" }}>
        <Fade left>
          <img src="./assets/ques-marks.png" alt="imag" />
        </Fade>
        <div />
      </div>
      <div className="sugggest-two" style={{ direction: "rtl" }}>
        <div>
          <Fade top>
            <h3 style={{ marginTop: "50px" }}>{data?.ar_desc}</h3>
          </Fade>
          <br />
          <Fade bottom>
            <h5>{data?.en_desc}</h5>
          </Fade>
          <div>
            <Fade bottom>
              <Link to="contact-us">
                <button className="suggest-btn">أضف اقتراحك</button>
              </Link>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suggestion;
