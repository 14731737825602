import React from "react";
import FacebookLogin from "react-facebook-login";
import { useDispatch } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { GOOGLE_LOGIN } from "../../redux/actions/types";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

const FacebookAuth = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState();
  const callBack = () => {};
  const responseFacebook = (response) => {
    console.log(response); // Handle the response from Facebook here
    const inputs = {
      name: response.name,
      email: response.email,
    };
    dispatch(reduxPost("auth/socialLogin", inputs, callBack, () => {}, setLoading, GOOGLE_LOGIN));
  };

  const handleCustomFacebookLogin = () => {
    // Trigger the Facebook Login when the custom button is clicked
    // You can call the `componentClicked` prop of `FacebookLogin` to simulate a click on the Facebook Login button.
    // This is useful when you want to use a custom button.
    document.querySelector(".kep-login-facebook").click();
  };

  return (
    <div>
      <button className="facebook-button" type="button" onClick={() => handleCustomFacebookLogin()}>
        <span style={{ marginRight: "20px" }}>تسجيل الدخول باستخدام فيسبوك</span>{" "}
        <FaFacebookF color="#4c69ba" className="icon" size={40} />
      </button>
      <FacebookLogin
        buttonStyle={{ display: "none" }}
        appId="217092181382065"
        autoLoad={false}
        fields="name,email"
        callback={responseFacebook}
      />
    </div>
  );
};

export default FacebookAuth;
