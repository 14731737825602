import React from "react";
import { imgUrl } from "../../../variables";
import style from "./message.module.css";
const Message = ({ item }) => {
  return (
    <div className={style.message}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* <h1 className={style.headText}> {item?.ar_title}</h1> */}
            <div dangerouslySetInnerHTML={{ __html: item?.ar_description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
