import {
  GET_ABOUT,
  GET_CATEGORIES,
  GET_CONTACT,
  GET_SLIDERS,
} from "../actions/types";

const initialState = {
  sliders: [],
  categories: [],
  contact_us: {},
  about_us: [],
};

const webDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SLIDERS:
      return {
        ...state,
        sliders: action.payload,
      };
    case GET_CATEGORIES: 
      return {
        ...state,
        categories: action.payload,
      };
    case GET_ABOUT:
      return {
        ...state,
        about_us: action.payload,
      };
    case GET_CONTACT:
      return {
        ...state,
        contact_us: action.payload,
      };
    default:
      return state;
  }
};

export default webDataReducer;
