import React from "react";
import { useDispatch } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { BiLoaderAlt } from "react-icons/bi";
import "./auth.css";
import AuthInput from "../ReusableComponents/AuthInput";
import PasswordInput from "../ReusableComponents/PasswordInput";

function Register({ setRegEmail, setCurrentStack, setShow }) {
  const [inputs, setInputs] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState({});
  console.log("ERR", err?.data?.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const callBack = () => {
    setCurrentStack("register-confirmation");
    setRegEmail(inputs.email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      reduxPost("auth/register", inputs, callBack, setErr, setLoading, "")
    );
  };
  return (
    <div>
      <form className="login" onSubmit={handleSubmit}>
        <AuthInput
          onChange={handleChange}
          placeholder="الاسم"
          name="name"
          err={err}
          min={2}
        />
        <AuthInput
          onChange={handleChange}
          className="mt-2"
          placeholder="البريد الإلكتروني"
          name="email"
          type="email"
          err={err}
        />
        <AuthInput
          onChange={handleChange}
          className="mt-2"
          placeholder="رقم الهاتف"
          name="mobile"
          err={err}
          min={2}
        />
        <PasswordInput
          onChange={handleChange}
          className="mt-2"
          placeholder="كلمة السر"
          name="password"
          err={err}
          min={2}
          type="password"
        />
        <PasswordInput
          onChange={handleChange}
          className="mt-2"
          placeholder="تأكيد كلمة السر"
          name="password_confirmation"
          err={err}
          min={2}
          type="password"
        />
        <button className="login-button mt-3">
          {loading ? <BiLoaderAlt className="loading" /> : "تأكيد"}
        </button>
        <p className="dont-have">
          هل لديك حساب بالفعل؟{" "}
          <span
            className="sign-up-now"
            onClick={() => setCurrentStack("login")}
          >
            تسجيل الدخول الان
          </span>
        </p>
      </form>
    </div>
  );
}

export default Register;
