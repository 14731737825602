import React from "react";

function ContactHeader() {
  return (
    <div className="contact-header-wrapper">
      <div className="contact-layer flex-center">
        <h1>تواصل معنا</h1>
      </div>
    </div>
  );
}

export default ContactHeader;
