import React from "react";
import { AiOutlineEye } from "react-icons/ai";

function PasswordInput({
  onChange,
  placeholder,
  min,
  type,
  className,
  name,
  err,
  style,
  defaultValue,
}) {
  console.log("Err Inside", err);
  const [show, setShow] = React.useState(false);
  return (
    <div>
      <label className={className}>{placeholder}</label>
      <div className="flex-center">
        <input
          className={`form-control login-input password-input`}
          placeholder={placeholder}
          type={show ? "text" : "password"}
          min={min}
          name={name}
          onChange={onChange}
          style={{
            ...style,
            borderLeft: "none",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
          }}
          defaultValue={defaultValue ? defaultValue : ""}
        />
        <button
          type="button"
          style={{ height: "38px", width: "40px" }}
          className="show-password-button"
          onClick={() => setShow(!show)}
        >
          <AiOutlineEye size={20} />
        </button>
      </div>
      {err?.data?.error?.[name]?.map((item, index) => {
        return (
          <p className="auth-error" key={index}>
            {item}
          </p>
        );
      })}
    </div>
  );
}

export default PasswordInput;
