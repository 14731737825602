import React from "react";
import { auth, facebook, provider } from "./config";
import { signInWithPopup } from "firebase/auth";
import { useDispatch } from "react-redux";
import { reduxPost } from "../../redux/actions/reusableActions";
import { GOOGLE_LOGIN } from "../../redux/actions/types";
import { FaFacebook } from "react-icons/fa";

function FacebookAuthButton() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState();
  const callBack = () => {};
  const handleClick = () => {
    signInWithPopup(auth, facebook)
      .then((data) => {
        const inputs = {};
        console.log("FIREBASE DATAS", data);
        dispatch(reduxPost("auth/socialLogin", inputs, callBack, () => {}, setLoading, GOOGLE_LOGIN));
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <button className="google-button" type="button" onClick={() => handleClick()}>
        <FaFacebook /> Login with Facebook
      </button>
    </div>
  );
}

export default FacebookAuthButton;
